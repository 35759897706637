.line {
	#previewer-header {
		.line-header {
			.slds-grid {
				min-height: 44px;
				align-items: center;

				.slds-col:first-child {
					padding-left: 1rem;
				}

				.slds-col:first-child + div {
					text-align: center;
					white-space: nowrap;
				}

				.slds-tooltip-trigger {
					display: inline-block;
					position: relative;

					&:last-child {
						button {
							border-radius: 0 0.25rem 0.25rem 0;
						}
					}

					button {
						width: 3rem;

						.slds-button__icon_stateful {
							height: 1.25rem;
							width: 1.25rem;
						}
					}

					.slds-popover {
					    position: absolute;
						top: 45px;
						z-index: 99999;
						left: 50%;
						-webkit-transform: translateX(-50%);
						transform: translateX(-50%);

						.slds-popover__body {
							white-space: nowrap;
						}
					}
				}
			}
		}
	}

	#previewer-container {
		.line-previewer {
			display: table;
			width: 100%;
			
			> div {
				display: table-cell;
				vertical-align: middle;
			}
			
			#left-container,
			#right-container {
				width: 50%;
			}

			.left-chevron,
			.right-chevron {
				cursor: pointer;
				background-color: #0070d2;
				border-radius: 50%;
				height: 50px;
				width: 50px;
				padding: 12px;

				svg {
					height: 24px;
					width: 24px;
					margin-left: -1px;
					margin-top: 2px;

					path {
						fill: #FFFFFF;
					}
				}
			}

			.left-chevron {
				margin-right: 25px;
				padding-right: 15px;
				float: right;
			}

			.right-chevron {
				margin-left: 25px;
				padding-left: 15px;
			}
		}
	}
}
