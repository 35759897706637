html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

#mount {
	height: inherit;
	width: inherit;
	background: #FFFFFF;
}

.mobilepush-device-previewer {
	min-width: 800px;
	background-color: #FFFFFF;
	
	#previewer-header {
		background-color: #F3F3F3;
	}
	
	#previewer-container {
		margin-top: 20px;
		text-align: center;
		background-color: #FFFFFF;
		
		iframe {
			width: 292px !important;
			height: 520px !important;
			border: 0;
			margin: 0 auto;
		}

		iframe.landscape {
			width: 520px !important;
			height: 292px !important;
		}
	}
}

.whatsapptemplate {
	.mobilepush-device-previewer {
		#previewer-container {
			iframe.landscape {
				width: 540px !important;
				height: 400px !important;
			}
		}
	}
}

.hidden {
	display: none !important;
}

.invisible {
	visibility: hidden;
}