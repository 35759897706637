.sms {
	#previewer-header {
		.sms-header {
			.slds-grid {
				min-height: 44px;
				align-items: center;
				
				.slds-col {
					text-align: center;
				}
			}
		}
	}
}