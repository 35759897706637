.push {
	#previewer-header {
		.push-header {
			.slds-grid {
				min-height: 44px;
				align-items: center;
				
				.slds-col {
					&:nth-child(4) {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						padding-right: 1rem;
						
						>div {
							padding: 0 2px;
							
							&:last-child {
								padding: 0px;
							}
						}
						
						#warning-container {
							margin-top: -1px;
						}
						
						#tooltip-container {
							.slds-form-element__icon {
								padding-top: 0px;
								margin-top: -1px;
							}
							section::before {
								background-color: initial;
							}
						}
					}
				}
			}

			.combobox-component {
				margin-top: 5px;
				
				.slds-form-element {
					display: inline-block;
					width: 200px;
				}

				.slds-icon_container {
					&.slds-icon-utility-check {
						color: #0070d2;
					}
				}
			}

			#warning-container {
				svg {
					height: 18px;
					width: 18px;
				}
			}

			#device-container {
				float: right;
				margin-right: 2.5px;
			}

			#device-view-container {
				margin-left: 2.5px;
			}
		}
	}
}